<template>
   <div class="secret">
      <h1>600</h1>
      <h3>Page Secret</h3>
   </div>
</template>

<script>
export default {
   name: 'Secret'
}
</script>

<style scoped>
   .secret h1 {
     font: 90px #f00 900;
   }
</style>